import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getExcuteCardThunk } from "./ExcuteThunk";

const initialState={
  isLoading:false,
  error:null,
  done:{}
}

export const excuteCard=createAsyncThunk('excuteCard',getExcuteCardThunk)

const excuteCardSlice=createSlice({
  name:"excuteCard",
  initialState,
  extraReducers:{
    [excuteCard.pending]:(state)=>{
      state.error=null;
      state.isLoading=true;
    },
    [excuteCard.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.done=payload;
    },
    [excuteCard.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})


export default excuteCardSlice.reducer