import customFetch from "../../utils/axios";


export const regionsDetailThunk=async({id},thunkAPI)=>{
  try {
    const resp=await customFetch.get(`region/${id}`,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng')
      }
    });
    return resp.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
}