import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllPublicationsThunk, getSpesificPublicationThunk } from "./publicationThunk";


const initialState={
  isLoading:false,
  publications:[],
  totalPublication:0,
  page:0,
  take:4,
  error:null
}

export const getAllPublications=createAsyncThunk('allPublication',getAllPublicationsThunk)
export const getSpesificPublications=createAsyncThunk('spesifikPublication',getSpesificPublicationThunk)
const publicationSlice=createSlice({
  name:"publications",
  initialState,
  reducers:{
    changePage:(state,{payload})=>{
      state.page=payload
    },
  },
  extraReducers:{
    [getAllPublications.pending]:(state)=>{
      state.isLoading=true;
    },
    [getAllPublications.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.totalPublication=payload.length;
      // state.publications=payload;
    },
    [getAllPublications.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    },
    [getSpesificPublications.pending]:(state)=>{
      state.isLoading=true;
    },
    [getSpesificPublications.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.publications=payload;
    },
    [getSpesificPublications.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload
    }
  }
})

export const {changePage}=publicationSlice.actions
export default publicationSlice.reducer;