import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { allPartnersThunk } from "./partnerThunk";


const initialState={
  isLoading:false,
  partners:[],
  error:'',
}

export const getAllPartners=createAsyncThunk('partners',allPartnersThunk)
const partnerSlice=createSlice({
  name:'partners',
  initialState,
  extraReducers:{
    [getAllPartners.pending]:(state)=>{
      state.isLoading=true;
    },
    [getAllPartners.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.partners=payload;
    },
    [getAllPartners.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})




// export const {}=partnerSlice.actions;
export default partnerSlice.reducer;