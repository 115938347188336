import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getCommonDonationThunk } from "./commonThunk";


const initialState={
  isLoading:false,
  error:null,
  commonDonation:[]
}

export const getCommonDonation=createAsyncThunk('getCommonDonation',getCommonDonationThunk)

const commonDonationSlice=createSlice({
  name:"commonDonation",
  initialState,
  extraReducers:{
    [getCommonDonation.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
      state.commonDonation=[];
    },
    [getCommonDonation.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.commonDonation=payload;
    },
    [getCommonDonation.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
      state.commonDonation=[];
    }
  }
})

export default commonDonationSlice.reducer;