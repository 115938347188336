import customFetch from "../../utils/axios";


export const allnewsThunk=async(_,thunkAPI)=>{
  try {
    const resp=await customFetch.get('/news?sticky=1',{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng')
      }
    });
    return resp.data.data.data;
  } catch (error) {
    console.log(error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
}