import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import LoginInput from "./LoginInput";
import { useTranslation } from "react-i18next";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { InputMask } from "primereact/inputmask";
import EyeIcon from "../icons/EyeIcon";
import EyeHideIcon from "../icons/EyeHideIcon";
import DateOfBirthSelect from "./DateOfBirthSelect";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { registerUser } from "../../featured/user/registerSlice";
import { ClipLoader } from "react-spinners";
import { closeModal } from "../../featured/user/userSlice";
import ErrorIcon from "../icons/ErrorIcon";
import InfoIconRegister from "../icons/InfoIconRegister";
import { UncontrolledTooltip } from "reactstrap";
import kohnesv from "../../assets/images/kohne.svg";
import yenisv from "../../assets/images/yeni.svg";
const loginInfos = {
  name: "",
  surname: "",
  email: "",
  operator: "070",
  phone: "",
  passport: "AA",
  id_cards: "",
  dob: "",
  bYear: "",
  bMonth: "",
  bDay: "",
  gender: "",
  password: "",
};
// male 1  female 2

const Register = () => {
  const dispatch = useDispatch();
  const {
    isLoading: registerLoading,
    error: registerError,
    success: registerSuccess,
  } = useSelector((state) => state.register);
  const [accepted, setAccepted] = useState(false);
  const [hideReg, setHideReg] = useState(false);
  const { t, i18n } = useTranslation("auth");
  const [login, setLogin] = useState(loginInfos);
  const {
    name,
    surname,
    email,
    phone,
    operator,
    passport,
    id_cards,
    dob,
    gender,
    password,
    bYear,
    bMonth,
    bDay,
  } = login;
  const yearTemp = new Date().getFullYear() - 15;
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };
  const years = Array.from(new Array(60), (val, index) => yearTemp - index);
  const months = Array.from(new Array(12), (val, index) => 1 + index);
  const getDays = () => {
    return new Date(bYear, bMonth, 0).getDate();
  };
  const days = Array.from(new Array(getDays()), (val, index) => 1 + index);
  const [dateError, setDateError] = useState("");
  const operators = [
    { name: "010" },
    { name: "040" },
    { name: "050" },
    { name: "051" },
    { name: "055" },
    { name: "060" },
    { name: "070" },
    { name: "077" },
    { name: "099" },
  ];
  const passports = [{ name: "AZE" }, { name: "AA" }];
  const reg = /^[A-ZÜİÖĞIƏÇŞa-züöğıəçş]+$/;
  const loginValidation = Yup.object({
    name: Yup.string()
      .required(t("name.error.empty"))
      .min(3, t("name.error.min"))
      .max(15, t("name.error.max")),
    surname: Yup.string()
      .required(t("surname.error.empty"))
      .min(3, t("surname.error.min")),
    email: Yup.string()
      .required(t("email.error.empty"))
      .email(t("email.error.wrong"))
      .max(100),
    phone: Yup.string()
      .required(t("phone.error.empty"))
      .min(9, t("phone.error.wrong"))
      .max(9, t("phone.error.wrong")),
    password: Yup.string()
      .required(t("password.error.empty"))
      .min(6, `${t("password.error.wrong")}`),
    id_cards: Yup.string()
      .required(t("ID_card.error.empty"))
      .min(7, t("ID_card.error.min"))
      .max(9, t("ID_card.error.max")),
    gender: Yup.string().required(t("gender.error")),
  });

  useEffect(() => {
    if (registerSuccess) {
      dispatch(closeModal());
    }
  }, [registerSuccess, dispatch]);

  const registerSubmit = async () => {
    try {
      window.localStorage.removeItem("user");
      window.sessionStorage.removeItem("user");
      dispatch(
        registerUser({
          name,
          surname,
          email,
          phone: `${operator} ${phone}`,
          id_cards: `${passport}${id_cards}`,
          dob: `${bYear}-${bMonth}-${bDay}`,
          gender,
          password,
        })
      );
    } catch (error) {
      console.log(error);
      window.localStorage.removeItem("user");
      window.sessionStorage.removeItem("user");
    }
  };
  return (
    <div className="register">
      <Formik
        enableReinitialize
        initialValues={{
          name,
          surname,
          email,
          phone,
          id_cards,
          dob,
          gender,
          password,
          operator,
        }}
        validationSchema={loginValidation}
        onSubmit={() => {
          let current_date = new Date();
          let picked_date = new Date(bYear, bMonth - 1, bDay);
          let atleast14 = new Date(1970 + 14, 0, 1);
          let noMoreThan70 = new Date(1970 + 70, 0, 1);
          if (current_date - picked_date < atleast14) {
            setDateError(t("birthday.error"));
          } else if (current_date - picked_date > noMoreThan70) {
            setDateError(t("birthday.error"));
          } else if (bYear === "" || bMonth === "" || bDay === "") {
            setDateError(t("birthday.error"));
          } else {
            setDateError("");
            registerSubmit();
          }
        }}
      >
        {(formik) => (
          <Form>
            {registerError && registerError?.id_cards && (
              <p className="error_alert">
                {t("register_response.id_cards_used")}
              </p>
            )}
            {registerError && registerError?.email && (
              <p className="error_alert">{t("register_response.email_used")}</p>
            )}
            {registerError &&
              (!registerError?.id_cards || !registerError?.email) && (
                <p className="error_alert">
                  {t("register_response.other_error")}
                </p>
              )}
            {/* {registerError && <p className='error_alert'>{registerError !== 409 ? t('register_response.email_used'): t('register_response.other_error')}</p>} */}
            <div className="register_grid">
              <LoginInput
                type="text"
                requiredIcon="true"
                label={t("name.label")}
                name="name"
                placeholder={t("name.placeholder")}
                onChange={handleLoginChange}
                autoComplete="off"
                onKeyPress={(event) => {
                  if (!reg.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <LoginInput
                type="text"
                requiredIcon="true"
                label={t("surname.label")}
                name="surname"
                placeholder={t("surname.placeholder")}
                onChange={handleLoginChange}
                autoComplete="off"
                onKeyPress={(event) => {
                  if (!reg.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <LoginInput
              type="text"
              requiredIcon="true"
              label={t("email.label")}
              name="email"
              placeholder={t("email.placeholder")}
              onChange={handleLoginChange}
              autoComplete="off"
            />
            <div className="phone_div">
              <label htmlFor="" className="phone_label">
                {t("phone.label")} <span style={{ color: "red" }}> *</span>
              </label>
              <div className="phone_section">
                <select
                  name="operator"
                  value={operator}
                  id="phone_operator"
                  onChange={handleLoginChange}
                >
                  {/* <option value="" disabled></option> */}
                  {operators &&
                    operators.map((code, index) => (
                      <option value={code.name} key={index}>
                        {code.name}
                      </option>
                    ))}
                </select>
                <InputMask
                  className={`${
                    formik.touched.phone && formik.errors.phone
                      ? "error_phone"
                      : ""
                  }`}
                  type="text"
                  id="phone"
                  mask="999-99-99"
                  name="phone"
                  placeholder="999-99-99"
                  onChange={handleLoginChange}
                ></InputMask>
              </div>
              {formik.touched.phone && formik.errors.phone && (
                <p className="error_message">
                  <ErrorIcon /> {formik.errors.phone}
                </p>
              )}
            </div>
            <div className="g_b_grid">
              <div className="passport_div">
                <label htmlFor="" className="passport_label">
                  <span
                    id="title_left"
                    style={{ top: "3px", position: "relative" }}
                  >
                    <InfoIconRegister />
                  </span>
                  <UncontrolledTooltip placement="top" target="title_left">
                    {passport && passport === "AA" ? (
                      <img src={yenisv} alt="yeni_nesil" />
                    ) : (
                      <img src={kohnesv} alt="kohne_nesil" />
                    )}
                  </UncontrolledTooltip>
                  {t("ID_card.label")}
                  <span style={{ color: "red" }}> *</span>
                </label>
                <div className="passport_section">
                  <select
                    name="passport"
                    value={passport}
                    id="passport_series"
                    onChange={handleLoginChange}
                    className={
                      formik.errors.id_cards ? "passport_error_message" : ""
                    }
                  >
                    {/* <option value="" disabled></option> */}
                    {passports &&
                      passports.map((pass, index) => (
                        <option value={pass.name} key={index}>
                          {pass.name}
                        </option>
                      ))}
                  </select>
                  <LoginInput
                    type="string"
                    pattern="[0-9]*"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    maxLength="9"
                    // requiredIcon="true"
                    //label={t("ID_card.label")}
                    name="id_cards"
                    placeholder={t("ID_card.placeholder")}
                    onChange={handleLoginChange}
                  />
                </div>
              </div>
              <div className="gender_section">
                <label htmlFor="" className="gender_Label">
                  {t("gender.label")} <span style={{ color: "red" }}> *</span>
                </label>
                <div className="register_radioButton">
                  <input
                    type="radio"
                    name="gender"
                    id="man"
                    value="1"
                    onChange={handleLoginChange}
                    checked={gender === "1"}
                  />
                  <label htmlFor="man">{t("gender.man")}</label>
                  <input
                    type="radio"
                    name="gender"
                    id="woman"
                    value="2"
                    onChange={handleLoginChange}
                    checked={gender === "2"}
                  />
                  <label htmlFor="woman">{t("gender.woman")}</label>
                </div>
                {formik.touched.gender && formik.errors.gender && (
                  <p className="error_message">
                    <ErrorIcon /> {formik.errors.gender}
                  </p>
                )}
              </div>
            </div>
            <div className="birth">
              <label>
                {t("birthday.label")} <span style={{ color: "red" }}> *</span>
              </label>
              <DateOfBirthSelect
                bDay={bDay}
                bMonth={bMonth}
                bYear={bYear}
                days={days}
                months={months}
                years={years}
                handleRegisterChange={handleLoginChange}
                dateError={dateError}
              />
            </div>
            <div className="password_parent">
              <LoginInput
                type={hideReg === true ? "text" : "password"}
                label={t("password.label")}
                requiredIcon="true"
                name="password"
                password={true}
                placeholder={t("password.placeholder")}
                onChange={handleLoginChange}
              />
              <span className="eye_icon" onClick={() => setHideReg(!hideReg)}>
                {!hideReg ? <EyeIcon /> : <EyeHideIcon />}
              </span>
            </div>
            <div className="auth_save">
              <input
                id="accept"
                type="checkbox"
                name="accept"
                checked={accepted === true}
                onChange={() => setAccepted(!accepted)}
              />
              {i18n.language === "az" ? (
                <label htmlFor="accept">
                  <span
                    style={{ marginLeft: "7px" }}
                    onClick={() => window.open("/privacy")}
                  >
                    {t("check.privacy")}
                  </span>{" "}
                  <p>{t("check.and")}</p>
                  <span onClick={() => window.open("/terms")}>
                    {t("check.terms")}
                  </span>{" "}
                  {t("check.label")}
                </label>
              ) : (
                <label htmlFor="accept">
                  {t("check.label")} <span onClick={() => window.open("/privacy")}>{t("check.privacy")}</span>
                  <p>{t("check.and")}</p>
                  <span onClick={() => window.open("/terms")}>{t("check.terms")}</span>
                </label>
              )}
            </div>
            <button
              type="submit"
              className="auth_button register_submit"
              disabled={accepted === false || registerLoading}
            >
              {registerLoading ? (
                <>
                  <ClipLoader color="#fff" size={24} />
                </>
              ) : (
                <>{t("auth_button")}</>
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Register;
