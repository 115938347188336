import customFetch from "../../utils/axios";


export const subscribeUserThunk=async(url,user,thunkAPI)=>{
  try {
    const resp=await customFetch.post(url,user);
    return resp.data;
  } catch (error) {
    console.log(error.response.status)
    return thunkAPI.rejectWithValue(error.response.status);
  }
}