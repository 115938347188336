import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userSubscribeCancelThunk } from "./cancelSubscribeThunk";

const initialState = {
  isLoading: false,
  subscribeCancel: false,
  error: "",
};
export const userSubscribeCancel = createAsyncThunk(
  "user/subscribe-cancel",
  async (user, thunkAPI) => {
    return userSubscribeCancelThunk("user/cancel/donation", user, thunkAPI);
  }
);

const userCancelSubscribeSlice = createSlice({
  name: "subscribeCancel",
  initialState,
  extraReducers: {
    [userSubscribeCancel.pending]: (state) => {
      state.isLoading = true;
      state.subscribeCancel = false;
      state.error = "";
    },
    [userSubscribeCancel.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.subscribeCancel = true;
      state.error = "";
    },
    [userSubscribeCancel.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.subscribeCancel = false;
      state.error = payload;
    },
  },
});

export default userCancelSubscribeSlice.reducer;
