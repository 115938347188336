import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { newsDetailThunk } from "./newsDetailThunk";

const initialState={
  isLoading:false,
  error:null,
  newsDetails:{},
  media:[],
  sliders:[],
}

export const getNewsDetail=createAsyncThunk('newsDetails',newsDetailThunk)

const newsDetailsSlice=createSlice({
  name:"newsDetailsSlice",
  initialState,
  extraReducers:{
    [getNewsDetail.pending]:(state)=>{
      state.isLoading=true;
    },
    [getNewsDetail.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.newsDetails=payload;
      state.media=payload.media;
      state.sliders=payload.sliders;
    },
    [getNewsDetail.rejected]:(state,{payload})=>{
      state.error=payload;
    }
  }
})

export default newsDetailsSlice.reducer;