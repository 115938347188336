import { useTranslation } from 'react-i18next';
export default function DateOfBirthSelect({
  bDay,
  bMonth,
  bYear,
  days,
  months,
  years,
  handleRegisterChange,
  dateError,
}) {
  const {t} = useTranslation("header");
  return (
    <>
    <div className="birth_select">
      <select name="bDay"  defaultValue={bDay} onChange={handleRegisterChange}>
        <option value="" disabled>{t('date.day')}</option>
        {days.map((day, i) => (
          <option value={day < 10 ? `0${day}`:day} key={i} selected={day === bDay}>
            {day < 10 ? `0${day}`:day}
          </option>
        ))}
      </select>
      <select name="bMonth" defaultValue={bMonth}  onChange={handleRegisterChange}>
        <option value="" disabled>{t('date.month')}</option>
        {months.map((month, i) => (
          <option value={month <10 ? `0${month}`: month} key={i} selected={month === bMonth}>
            {month <10 ? `0${month}`: month}
          </option>
        ))}
      </select>
      <select name="bYear"  defaultValue={bYear} onChange={handleRegisterChange}>
        <option value="" disabled>{t('date.year')}</option>
        {years.map((year, i) => (
          <option value={ year} key={i} selected={year === bYear}>
            {year}
          </option>
        ))}
      </select>
    </div>
    {dateError && (
      <p className='error_message'>{dateError}</p>
      )}
    </>
  );
}