import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterNewsThunk } from "./newsFilterThunk";

const initialState={
  isLoading:false,
  filterMenu:false,
  error:null,
  page:1,
  paginate:8,
  regionID:'',
  news:[]
}

export const getFilterNews=createAsyncThunk('newsFilter',filterNewsThunk)

const newsFilterSlice=createSlice({
  name:"newsFilter",
  initialState,
  reducers:{
    changeRegion:(state,{payload})=>{
      state.regionID=payload;
    },
    changePageFilter:(state,{payload})=>{
      state.page=payload
    },
  },

  extraReducers:{
    [getFilterNews.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
    },
    [getFilterNews.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.news=payload;
      state.error=null
    },
    [getFilterNews.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export const {changeRegion,changePageFilter}=newsFilterSlice.actions

export default newsFilterSlice.reducer