import React, { Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './assets/sass/index.scss';
import { store } from './app/store';
import { Provider } from 'react-redux';
import App from './App';
import './i18next';
import reportWebVitals from './reportWebVitals';
import {PuffLoader} from 'react-spinners';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Suspense fallback={(<div className='lazy_loading'><PuffLoader color="#2697DC" size={200}/></div>)}>
      <App />
    </Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
