import customFetch from "../../utils/axios";


export const getDetailDonateThunk=async(donateId,thunkAPI)=>{
  const user=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.get(donateId,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng'),
        'Authorization':`Bearer ${JSON.parse(user)}`
      }
    });
    return resp.data.data;
  } catch (error) {
    console.log(error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
}