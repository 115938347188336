import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRulesThunk } from "./rulesThunk";


const initialState={
  isLoading:false,
  rules:{},
  error:null
}

export const getRules=createAsyncThunk('privacy',getRulesThunk)

const rulesSlice=createSlice({
  name:"privacy",
  initialState,
  extraReducers:{
    [getRules.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
    },
    [getRules.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.rules=payload;
    },
    [getRules.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export default rulesSlice.reducer;