import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getYearDonationThunk } from "./yearDonationThunk";


const initialState={
  year:new Date().getFullYear(),
  isLoading:false,
  error:null,
  yearDonation:{}
}

export const getYearDonation=createAsyncThunk('getyearDonation',getYearDonationThunk)

const regionDonationSlice=createSlice({
  name:"yearDonation",
  initialState,
  extraReducers:{
    [getYearDonation.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
      state.yearDonation={};
    },
    [getYearDonation.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.yearDonation=payload;
    },
    [getYearDonation.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
      state.yearDonation={};
    }
  }
})

export default regionDonationSlice.reducer;