import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDetailDonateThunk } from "./detailDonateThunk";

const initialState={
  isLoading:false,
  error:null,
  detailDonate:{}
}

export const getDetailDonate=createAsyncThunk('getDetailDonate',getDetailDonateThunk)

const detailDonateSlice=createSlice({
  name:"detailDonate",
  initialState,
  extraReducers:{
    [getDetailDonate.pending]:(state)=>{
      state.error=null;
      state.isLoading=true;
    },
    [getDetailDonate.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.detailDonate=payload;
    },
    [getDetailDonate.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})


export default detailDonateSlice.reducer