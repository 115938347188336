import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAddCardThunk } from "./addCartThunk";

const initialState={
  userId:null,
  isLoading:false,
  error:null,
  cardSave:{}
}

export const addCard=createAsyncThunk('addCard',getAddCardThunk)

const addCardSlice=createSlice({
  name:"saveCard",
  initialState,
  reducers:{
      addUserID:(state,{payload})=>{
          state.userId=payload
      }
  },
  extraReducers:{
    [addCard.pending]:(state)=>{
      state.error=null;
      state.isLoading=true;
    },
    [addCard.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.cardSave=payload;
    },
    [addCard.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})


export const {addUserID}=addCardSlice.actions

export default addCardSlice.reducer