import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { structureDetailThunk } from "./structureDetailThunk";


const initialState={
  isLoading:false,
  structure:{},
  error:null
}

export const getDetailStructure=createAsyncThunk('allStructure',structureDetailThunk)

const structureDetailSlice=createSlice({
  name:"structureDetail",
  initialState,
  extraReducers:{
    [getDetailStructure.pending]:(state)=>{
      state.isLoading=true;
    },
    [getDetailStructure.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.structure=payload;
    },
    [getDetailStructure.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export default structureDetailSlice.reducer;