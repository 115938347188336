import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getActiveDonationThunk } from "./activeDonationThunk";


const initialState={
  isLoading:false,
  error:null,
  activeDonate:[],
}

export const getActiveDonation=createAsyncThunk('getActiveDonation',getActiveDonationThunk)

const userActiveDonationSlice=createSlice({
  name:"userActiveDonation",
  initialState,
  extraReducers:{
    [getActiveDonation.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
      state.activeDonate=[]
    },
    [getActiveDonation.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.activeDonate=payload;
    },
    [getActiveDonation.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
      state.activeDonate=[];
    }
  }
})

export default userActiveDonationSlice.reducer;