import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAboutThunk } from "./aboutThunk";

const initialState={
  isLoading:false,
  error:null,
  about:{}
}

export const getAbout=createAsyncThunk('about',getAboutThunk)

const aboutSlice=createSlice({
  name:"about",
  initialState,
  extraReducers:{
    [getAbout.pending]:(state)=>{
      state.error=null;
      state.isLoading=true;
    },
    [getAbout.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.about=payload;
    },
    [getAbout.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})


export default aboutSlice.reducer