import customFetch from "../../utils/axios";


export const getUserDataThunk=async(_,thunkAPI)=>{
  const user=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.get(`/user/profile`,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng'),
        'Authorization':`Bearer ${JSON.parse(user)}`
      }
    });
    return resp.data.data;
  } catch (error) {
      console.log(error.response.status)
      if(error.response.status === 401){
        window.location.href='/'
        sessionStorage.removeItem('user');
        localStorage.removeItem('user');
        return  window.location.reload();
      }
      return thunkAPI.rejectWithValue(error.message);
  }
}