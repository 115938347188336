
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import infoImg from '../assets/images/background.png'
const InfoSection = () => {
  const {t}=useTranslation('home');
  return (
    <div className='container info'>
      <div className="info__background" style={{
         backgroundImage: `linear-gradient(0deg, rgba(38, 151, 220, 0.6), rgba(38, 151, 220, 0.6)), url(${infoImg})`
      }}>
          <h2 className='info__title'>
            {t('donate.head')}, {' '}
            <span>{t('donate.desc')}</span>
          </h2>
          <Link to="/donate" className='info__donate-btn'>{t('donate.button')}</Link>
      </div>
    </div>
  )
}

export default InfoSection