import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import { registerUserThunk } from "./registerThunk";



const initialState={
  isLoading:false,
  register:{},
  success:false,
  error:""
}
export const registerUser=createAsyncThunk('user/register',async(user,thunkAPI)=>{
  return registerUserThunk('auth/register',user,thunkAPI);
})


const registerSlice=createSlice({
  name:'register',
  initialState,
  extraReducers:{
    [registerUser.pending]:(state)=>{
      state.isLoading=true;
      state.success=false;
      state.error='';
    },
    [registerUser.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.success=true;
      state.register=payload;
      state.error='';
    },
    [registerUser.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.success=false;
      state.error=payload;
    }
  }
})

export default registerSlice.reducer;
