import customFetch from "../../utils/axios";
import { toast } from "react-toastify";

export const userSubscribeCancelThunk = async (url, user, thunkAPI) => {
  const userData =
    localStorage.getItem("user") || sessionStorage.getItem("user") || [];
  try {
    const resp = await customFetch.post(url, user, {
      headers: {
        "x-localization": localStorage.getItem("i18nextLng"),
        Authorization: `Bearer ${JSON.parse(userData)}`,
      },
    });
    toast.success(
      localStorage.getItem("i18nextLng") !== "en"
        ? "Müvafiq təlimat e-poçtunuza göndərildi"
        : "The relevant instructions have been sent to your email"
    );
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
};
