import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import { userCardDeleteThunk } from "./deleteCartThunk";



const initialState={
  isLoading:false,
  deleted:"",
  success:false,
  error:""
}
export const userCardDelete=createAsyncThunk('user/card-delete',async(user,thunkAPI)=>{
  return userCardDeleteThunk('user/delete/card',user,thunkAPI);
})

const lang=localStorage.getItem('i18nextLng');
const userDeleteCardSlice=createSlice({
  name:'cardDelete',
  initialState,
  extraReducers:{
    [userCardDelete.pending]:(state)=>{
      state.isLoading=true;
      state.success=false;
      state.error='';
    },
    [userCardDelete.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.deleted=payload;
      state.success=true;
      state.error='';
    },
    [userCardDelete.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.success=false;
      state.error=payload;
    }
  }
})

export default userDeleteCardSlice.reducer;
