import {useField } from 'formik';
import React from 'react'
import ErrorIcon from '../icons/ErrorIcon';
const LoginInput = ({placeholder,label,password=false,disabled=false,requiredIcon=false, type=false,...props}) => {
    const [field,meta]=useField(props)
  return (
    <div className='login_input' >
      <label>{label}{requiredIcon && <span style={{color:'red'}}>{" "}*</span>}</label>
      <div>
        <input {...props}  type={type} disabled={disabled} className={`${meta.touched && meta.error && 'error_input'}`} placeholder={placeholder} autoComplete="new-value"/>
      </div>
        {meta.touched && meta.error && <p className='error_message'><ErrorIcon/> {meta.error}</p>}
    </div>
  )
}

export default LoginInput