import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";


export const updatePasswordThunk=async(url,user,thunkAPI)=>{
  try {
    const resp=await customFetch.post(url,user);
    window.location.href="/update/success"
    return resp.data;
  } catch (error) {
    console.log(error.response.status)
    return checkForUnauthorizedResponse(error,thunkAPI)
  }
}