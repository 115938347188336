import customFetch from "../../utils/axios";


export const changePasswordThunk=async(url,user,thunkAPI)=>{
    const userData=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.post(url,user,{
        headers:{
          'x-localization': localStorage.getItem('i18nextLng'),
          'Authorization':`Bearer ${JSON.parse(userData)}`
        }
      });
    return resp.data;
  } catch (error) {
    console.log(error.response.status)
    return thunkAPI.rejectWithValue(error.response.status)
  }
}