import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import ArrowPrevIcon from '../components/icons/ArrowPrevIcon'
import InfoSection from '../components/InfoSection'

const EmailSendRegister = () => {
  const {keyword}=useParams()
  const {t}=useTranslation('verify');

  return (
    <>
    <div className='container register_page'>
      <h1 className='register__email__title'>{t('verify.title')}</h1>
      <div className='register__email__desc'>
          <p>{t('verify.email_first_desc')}</p>
          <p>{t('verify.email_second_desc')} {" "} :{" "} <strong>{keyword}</strong></p>
      </div>
      <Link to="/" className='error_page__button'><ArrowPrevIcon/>{t('back')} </Link>
    </div>
    <InfoSection/>
    </>
  )
}

export default EmailSendRegister