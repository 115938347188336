import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getRegionDonationThunk } from "./regionDonationThunk";


const initialState={
  isLoading:false,
  error:null,
  regionDonation:{},
  fondDonation:null,
}

export const getRegionDonation=createAsyncThunk('getRegionDonation',getRegionDonationThunk)

const regionDonationSlice=createSlice({
  name:"regionDonation",
  initialState,
  extraReducers:{
    [getRegionDonation.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
      state.regionDonation={};
      state.fondDonation=null;
    },
    [getRegionDonation.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.regionDonation=payload.regions;
      state.fondDonation=payload.common;
    },
    [getRegionDonation.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
      state.regionDonation={};
      state.fondDonation=null;
    }
  }
})

export default regionDonationSlice.reducer;