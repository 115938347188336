import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserYearDonationThunk } from "./reportsFilterThunk";



export const getUserReports=createAsyncThunk('getuseReport',getUserYearDonationThunk)
const reportSlice=createSlice({
  name:'report',
  initialState:{
    selectedMonth:new Date().getMonth()+1,
    years:["2018","2019","2020",'2021','2022',"2023"],
    selectedMonthValue:"",
    selectedYear:new Date().getFullYear(),
    openDatePicker:false,
    isloading:false,
    error:'',
    userReport:[],
  },
  reducers:{
    openDate:(state)=>{
      state.openDatePicker=true
    },
    closeDate:(state)=>{
      state.openDatePicker=false
    },
    filterReports:(state,{payload})=>{
      state.selectedMonth=payload +1;
    },
    filterYear:(state,{payload})=>{
      state.selectedYear=payload
    },
  },
  extraReducers:{
    [getUserReports.pending]:(state)=>{
      state.isloading=true;
    },
    [getUserReports.fulfilled]:(state,{payload})=>{
      state.isloading=false;
      state.userReport=payload;
    },
    [getUserReports.rejected]:(state,{payload})=>{
      state.error=payload;
    }
  }
})

export const {openDate,closeDate,filterReports,filterYear,increaseYear}=reportSlice.actions
export default reportSlice.reducer