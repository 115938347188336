import customFetch from "../../utils/axios";
import { toast } from "react-toastify";

export const userSubscribeDeleteThunk = async (url, user, thunkAPI) => {
  const userData =
    localStorage.getItem("user") || sessionStorage.getItem("user") || [];
  try {
    //console.log(url);
    //console.log("us " + user);
    const resp = await customFetch.post(url, user, {
      headers: {
        Authorization: `Bearer ${JSON.parse(userData)}`,
      },
    });
    toast.success(
      localStorage.getItem("i18nextLng") !== "en"
        ? "Abunəniz uğurla silindi"
        : "Your subscription has been deleted successfully"
    );
    return resp.data;
  } catch (error) {
    console.log("error " + error);
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
};
