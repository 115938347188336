import customFetch from "../../utils/axios";


export const getYearDonationThunk=async(_,thunkAPI)=>{
  const {year}=thunkAPI.getState().yearDonation;
  try {
    const resp=await customFetch.get(`/donations/year?year=${year}`,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng')
      }
    });
    return resp.data.data.month;
  } catch (error) {
    console.log(error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
}