import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";


export const forgetPasswordThunk=async(url,user,thunkAPI)=>{
  try {
    const resp=await customFetch.post(url,user);
    window.location.href=`/forget/${user.email}`;
    return resp.data;
  } catch (error) {
    console.log(error.response.status)
    if(error.response.status === 403){
      return thunkAPI.rejectWithValue(error.response.status);
    }
    if(error.response.status === 404){
      return thunkAPI.rejectWithValue(error.response.status);
    }
    return checkForUnauthorizedResponse(error,thunkAPI)
  }
}