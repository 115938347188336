import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { allTrusteeThunk } from "./trusteeThunk";


const initialState={
  isLoading:true,
  trustee:[],
  error:''
}

export const getAllTrustee=createAsyncThunk('trustee',allTrusteeThunk)


const trusteeSlice=createSlice({
  name:'trustee',
  initialState,
  extraReducers:{
    [getAllTrustee.pending]:(state)=>{
      state.isLoading=true;
    },
    [getAllTrustee.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.trustee=payload;
    },
    [getAllTrustee.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload
    }
  }
})



export default trusteeSlice.reducer;