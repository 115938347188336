import customFetch from "../../utils/axios";


export const registerUserThunk=async(url,user,thunkAPI)=>{
  try {
    const resp=await customFetch.post(url,user);
    window.location.href=`/email/${resp.data.data.user.email}`;
    return resp.data;
  } catch (error) {
    if(error.response.status === 409){
      return thunkAPI.rejectWithValue(error.response.data.message)
    }else{
      console.log(error.response.status)
      return thunkAPI.rejectWithValue(error.response.status)
    }
  }
}