import customFetch from "../../utils/axios";


export const getDonatePaymentThunk=async(paymentDonate,thunkAPI)=>{
  const user=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.get(paymentDonate,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng'),
        'Authorization':`Bearer ${JSON.parse(user)}`
      }
    });
    window.location.href=resp.data.data.redirect_url
    //console.log(resp.data.data);
    return resp.data.data;
  } catch (error) {
    console.log(error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
}