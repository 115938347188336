import customFetch from "../../utils/axios";


export const updateProfileThunk=async(url,user,thunkAPI)=>{
    const userData=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.post(url,user,{
        headers:{
          'x-localization': localStorage.getItem('i18nextLng'),
          'Authorization':`Bearer ${JSON.parse(userData)}`
        }
      });
    return resp.data;
  } catch (error) {
    console.log(error.response.status)
    if(error.response.status !== 409){
      window.location.href='/'
      sessionStorage.removeItem('user');
      localStorage.removeItem('user');
      return window.location.reload();
    }else{
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
}
export const updateProfileFLNAmeThunk=async(url,user,thunkAPI)=>{
    const userData=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.post(url,user,{
        headers:{
          'x-localization': localStorage.getItem('i18nextLng'),
          'Authorization':`Bearer ${JSON.parse(userData)}`
        }
      });
    return resp.data;
  } catch (error) {
    console.log(error.response.status)
    if(error.response.status !== 409){
      window.location.href='/'
      sessionStorage.removeItem('user');
      localStorage.removeItem('user');
      return window.location.reload();
    }else{
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
}