import React from 'react'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import LoginInput from './LoginInput';
import { useTranslation } from 'react-i18next';
import EyeIcon from '../icons/EyeIcon';
import EyeHideIcon from '../icons/EyeHideIcon';
import { useDispatch } from 'react-redux';
import { changeSave, forgetModalOpen, loginUser } from '../../featured/user/userSlice';
import { useSelector } from 'react-redux';
import {ClipLoader} from 'react-spinners';
import { useEffect } from 'react';
const loginInfos = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch=useDispatch();
  const {user,isLoading:loginLoading,error:errorLogin,success:SuccessLogin,saveMe}=useSelector(state=>state.user)
  const [remember,setRemember]=useState(false)
  const [hide,setHide]=useState(false);
  const {t}=useTranslation('auth')
  const [login, setLogin] = useState(loginInfos);
  const { email, password} = login;
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  useEffect(()=>{
    if(SuccessLogin){
      window.location.href='/donate'
    }
  },[SuccessLogin])
  useEffect(()=>{
      if(saveMe){
        if(user !== [] || user.length > 0){
          window.localStorage.setItem('user', JSON.stringify(user.access_token))
          window.sessionStorage.removeItem('user');
        }
      }else{
        if(user !== [] || user.length > 0){
          window.sessionStorage.setItem('user',JSON.stringify(user.access_token))
          window.localStorage.removeItem('user');
        }
      }
  },[remember, saveMe, user])
  const loginValidation = Yup.object({
    email: Yup.string()
      .required(t('email.error.empty'))
      .email(t('email.error.wrong'))
      .max(100),
    password: Yup.string().required(t('password.error.empty')).min(6,`${t('password.error.wrong')}`),
  });
  const loginSubmit = async () => {
    try {
      dispatch(loginUser({email:email,password:password}))
    } catch (error) {
      console.log(error)
    }
  };
  return (
    <div>
      <Formik
            enableReinitialize
            initialValues={{
              email,
              password,
            }}
            validationSchema={loginValidation}
            onSubmit={() => {
              loginSubmit();
            }}
          >
            {(formik) => (
              <Form className='auto_login'>
                {errorLogin && <p className='error_alert'>{errorLogin === 403 ? t('login_response.accept_mail'): t('login_response.wrong_data')}</p>}
                <div>
                <LoginInput
                  type="text"
                  label={t('email.label')}
                  name="email"
                  placeholder={t('email.placeholder')}
                  onChange={handleLoginChange}
                  autoComplete="off"
                />
                <div className='password_parent'>
                  <LoginInput
                    type={hide ? 'text':'password'}
                    label={t('password.label')}
                    name="password"
                    password={true}
                    placeholder={t('password.placeholder')}
                    onChange={handleLoginChange}
                  />
                  <span className='eye_icon' onClick={()=>setHide(!hide)}>{!hide ? <EyeIcon/>: <EyeHideIcon/>}</span>
                </div>
                <div className='auth_save'>
                  <input id="custom_save" type="checkbox" onChange={()=>dispatch(changeSave())}/>
                  <label htmlFor="custom_save">{t('save_me')}</label>
                </div>
                </div>
                <button type="submit" className="auth_button" disabled={loginLoading}>
                  {loginLoading ? <><ClipLoader color="#fff" size={24}/></>:<>{t('auth_button')}</>}
                </button>
              </Form>
            )}
          </Formik>
          <button type="button" className='button_forget register_submit' onClick={()=>dispatch(forgetModalOpen())}>{t('forget_button')}</button>
    </div>
  )
}

export default Login
