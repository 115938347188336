import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { liveTickerThunk } from "./liveTickerThunk";


const initialState={
  isloading:false,
  error:'',
  result:[]
}


export const getLiveTicker=createAsyncThunk('regionDetail',liveTickerThunk)

const liveTickerSlice=createSlice({
  name:"newsDetailsSlice",
  initialState,
  extraReducers:{
    [getLiveTicker.pending]:(state)=>{
      state.isLoading=true;
    },
    [getLiveTicker.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.result=payload.data;
    },
    [getLiveTicker.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export default liveTickerSlice.reducer;