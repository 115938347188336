import customFetch from "../../utils/axios";


export const getAllSubscriptionsThunk=async(_,thunkAPI)=>{
  try {
    const resp=await customFetch.get(`/subscriptions`,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng')
      }
    });
    return resp.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
}