import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSocialThunk } from "./socialThunk";


const initialState={
  isLoading:false,
  social:{},
  error:null
}

export const getAllSocial=createAsyncThunk('social',getSocialThunk)

const socialSlice=createSlice({
  name:"social",
  initialState,
  extraReducers:{
    [getAllSocial.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
    },
    [getAllSocial.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.social=payload;
    },
    [getAllSocial.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export default socialSlice.reducer;