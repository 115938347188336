import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import { getUserDataThunk } from "./getUserThunk";



const initialState={
  isLoading:false,
  name:"",
  user:{},
  error:false,
}


export const getUserData=createAsyncThunk('gerUserData',getUserDataThunk)

const getUserDataSlice=createSlice({
  name:'userData',
  initialState,
  extraReducers:{
    [getUserData.pending]:(state)=>{
        state.isLoading=true;
        state.error=false;
    },
    [getUserData.fulfilled]:(state,{payload})=>{
        state.isLoading=false;
        state.error=false;
        state.user=payload;
        state.name=payload.name +" " + payload.surname;
    },
    [getUserData.rejected]:(state,{payload})=>{
        state.isLoading=false;
        state.error={payload};
    }
  }
})

export default getUserDataSlice.reducer;
