import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import React, { useLayoutEffect } from "react";
import ProtectedRoute from "./pages/ProtectedRoute";
import { useSelector } from "react-redux";
import Auth from "./components/auth/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailSendRegister from "./pages/EmailSendRegister";
import EmailVerifyAlready from "./pages/EmailVerifyAlready";
import SoonPage from "./pages/SoonPage";
import i18n from 'i18next';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

// const HomePage=React.lazy(()=>new Promise((resolve,reject)=>setTimeout(()=>resolve(import('./pages/HomePage')),1200)));
const HomePage = React.lazy(() => import("./pages/HomePage"));
const SharedLayout = React.lazy(() => import("./pages/SharedLayout"));
const MissionPage = React.lazy(() => import("./pages/MissionPage"));
const AboutPage = React.lazy(() => import("./pages/AboutPage"));
const Structure = React.lazy(() => import("./pages/Structure"));
const StructureDetailPage = React.lazy(() =>
  import("./pages/StructureDetailPage")
);
const Regions = React.lazy(() => import("./pages/Regions"));
const RegionDetailPage = React.lazy(() => import("./pages/RegionDetailPage"));
const NewsPage = React.lazy(() => import("./pages/NewsPage"));
const NewsDetailPage = React.lazy(() => import("./pages/NewsDetailPage"));
const SearchNewsPage = React.lazy(() => import("./pages/SearchNewsPage"));
const ReportsPage = React.lazy(() => import("./pages/ReportsPage"));
const DonatePage = React.lazy(() => import("./pages/DonatePage"));
const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));
const PublicationsPage = React.lazy(() => import("./pages/PublicationsPage"));
const PrivacyPage = React.lazy(() => import("./pages/PrivacyPage"));
const TermsPage = React.lazy(() => import("./pages/TermsPage"));
const SharedCabinetLayout = React.lazy(() =>
  import("./pages/user-profile/SharedCabinetLayout")
);
const ProfileInfo = React.lazy(() =>
  import("./pages/user-profile/ProfileInfo")
);
const ChangePassword = React.lazy(() =>
  import("./pages/user-profile/ChangePassword")
);
const CabinetReportsPage = React.lazy(() =>
  import("./pages/user-profile/CabinetReportsPage")
);
const CabinetPayment = React.lazy(() =>
  import("./pages/user-profile/CabinetPayment")
);
const ForgetPassword = React.lazy(() => import("./pages/ForgetPassword"));
const EmailVerify = React.lazy(() => import("./pages/EmailVerify"));
const PasswordSuccess = React.lazy(() => import("./pages/PasswordSuccess"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const DonateDetail = React.lazy(() =>
  import("./pages/user-profile/DonateDetail")
);
const DonationCancelSurvey = React.lazy(() =>
  import("./pages/user-profile/DonationCancelSurvey")
);

function App() {
  document.documentElement.lang = i18n.language;
  const { authModal } = useSelector((state) => state.user);
  return (
    <>
      <Router>
        <Wrapper>
          <Routes>
            <Route path="/" element={<SharedLayout />}>
              <Route index element={<HomePage />} />
              <Route path="mission" element={<MissionPage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="structure" element={<Structure />} />
              <Route path="structure/:id" element={<StructureDetailPage />} />
              <Route path="regions" element={<Regions />} />
              <Route path="regions/:id" element={<RegionDetailPage />} />
              <Route
                path="reports"
                element={
                  process.env.REACT_APP_DONATE === "production" ? (
                    <SoonPage />
                  ) : (
                    <ReportsPage />
                  )
                }
              />
              <Route path="news" element={<NewsPage />} />
              <Route path="news/:id" element={<NewsDetailPage />} />
              <Route path="search/:keyword" element={<SearchNewsPage />} />
              <Route
                path="donate"
                element={
                  process.env.REACT_APP_DONATE === "production" ? (
                    <SoonPage />
                  ) : (
                    <DonatePage />
                  )
                }
              />
              <Route
                path="donate/:id"
                element={
                  process.env.REACT_APP_DONATE === "production" ? (
                    <SoonPage />
                  ) : (
                    <DonateDetail />
                  )
                }
              />
              <Route path="publication" element={<PublicationsPage />} />
              <Route path="privacy" element={<PrivacyPage />} />
              <Route path="terms" element={<TermsPage />} />
              <Route
                path="cabinet"
                element={
                  <ProtectedRoute>
                    <SharedCabinetLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<ProfileInfo />} />
                <Route path="password" element={<ChangePassword />} />
                <Route
                  path="reports"
                  element={
                    process.env.REACT_APP_DONATE === "production" ? (
                      <SoonPage />
                    ) : (
                      <CabinetReportsPage />
                    )
                  }
                />
                <Route
                  path="payment"
                  element={
                    process.env.REACT_APP_DONATE === "production" ? (
                      <SoonPage />
                    ) : (
                      <CabinetPayment />
                    )
                  }
                />
              </Route>
              <Route path="forget/:email" element={<ForgetPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="email/verify/success" element={<EmailVerify />} />
              <Route path="update/success" element={<PasswordSuccess />} />
              <Route
                path="email/verify/already"
                element={<EmailVerifyAlready />}
              />
              <Route path="email/:keyword" element={<EmailSendRegister />} />
              {/* RA  */}
              <Route
                path="donation/cancel"
                element={
                  <ProtectedRoute>
                    <DonationCancelSurvey />
                  </ProtectedRoute>
                }
              />
              <Route path="not-found" element={<ErrorPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </Wrapper>
      </Router>
      {authModal && <Auth />}
      <ToastContainer position="bottom-right" />
    </>
  );
}

export default App;
