import React from 'react'

const ModalCloseIcon = () => {
  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" transform="matrix(-1 0 0 1 48 0.5)" fill="#EDF9FF" fillOpacity="0.5"/>
      <path d="M16.5003 16.5L32 32.5M31.4997 16.5L16 32.4999" stroke="#2697DC" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}

export default ModalCloseIcon
