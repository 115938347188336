import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllSubscriptionsThunk } from "./subscribtionThunk";


const initialState={
  isLoading:false,
  subscriptions:[],
  error:null
}

export const getAllSubscriptions=createAsyncThunk('allSubscription',getAllSubscriptionsThunk)

const subscriptionsSlice=createSlice({
  name:"subscriptions",
  initialState,
  extraReducers:{
    [getAllSubscriptions.pending]:(state)=>{
      state.isLoading=true;
      state.error=null
    },
    [getAllSubscriptions.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.subscriptions=payload;
    },
    [getAllSubscriptions.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export default subscriptionsSlice.reducer;