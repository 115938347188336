import { createSlice} from "@reduxjs/toolkit";


const initialState={
  openMenu:false
}


const menuSlice=createSlice({
  name:'menu',
  initialState,
  reducers:{
    openMobMenu:(state)=>{
      state.openMenu=true;
    },
    closeMobMenu:(state)=>{
      state.openMenu=false
    }
  }
})

export const {openMobMenu,closeMobMenu}=menuSlice.actions
export default menuSlice.reducer;