import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import { loginUserThunk } from "./userThunk";



const initialState={
  isLoading:false,
  user:localStorage.getItem('user') || sessionStorage.getItem('user') || [],
  authModal:false,
  forget:false,
  success:false,
  error:"",
  saveMe:false
}


export const loginUser=createAsyncThunk('user/loginUser',async(user,thunkAPI)=>{
  return loginUserThunk('auth/login',user,thunkAPI);
})

const userSlice=createSlice({
  name:'user',
  initialState,
  reducers:{
    openModal:(state)=>{
      state.authModal=true;
    },
    closeModal:(state)=>{
      state.authModal=false;
    },
    changeSave:(state,{payload})=>{
      state.saveMe=!state.saveMe
    },
    forgetModalOpen:(state)=>{
      state.forget=true;
    },
    forgetModalClose:(state)=>{
      state.forget=false;
    }
  },
  extraReducers:{
    [loginUser.pending]:(state)=>{
      state.isLoading=true;
      state.success=false;
      state.error=''
    },
    [loginUser.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error='';
      state.success=true;
      state.user=payload
    },
    [loginUser.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.success=false
      state.error=payload
    }
  }
})

export const {openModal,closeModal,changeSave,forgetModalClose,forgetModalOpen}=userSlice.actions;
export default userSlice.reducer;
