import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSearchThunk } from "./searchThunk";


const initialState={
  isLoading:false,
  result:"",
  page:1,
  paginate:8,
  error:null
}

export const getSearchNews=createAsyncThunk('getSearchNews',getSearchThunk)
const searchSlice=createSlice({
  name:"publications",
  initialState,
  reducers:{
    changePage:(state,{payload})=>{
      state.page=payload
    },
  },
  extraReducers:{
    [getSearchNews.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
      state.result="";
    },
    [getSearchNews.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.result=payload;
    },
    [getSearchNews.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
      state.result="";
    }
  }
})

export const {changePage}=searchSlice.actions
export default searchSlice.reducer;