import { configureStore } from "@reduxjs/toolkit";
import submenuReducer from "../featured/submenu/subMenuSlice";
import newsReducer from "../featured/news/newsSlice";
import reportReducer from "../featured/reports_filter/reportsSlice";
import partnerReducer from "../featured/partner/partnerSlice";
import bannerReducer from "../featured/banners/bannerSlice";
import trusteeReducer from "../featured/trustee/trusteeSlice";
import newsDetailReducer from "../featured/news/newsDetailSlice";
import regionReducer from "../featured/regions/regionSlice";
import regionDetailReducer from "../featured/regions/regionDetailSlice";
import publicationReducer from "../featured/publications/publicationSlice";
import missionReducer from "../featured/mission/missionSlice";
import StructureReducer from "../featured/structure/StructureSlice";
import structureDetailReducer from "../featured/structure/structureDetailSlice";
import AboutReducer from "../featured/about/aboutMeSlice";
import socialReducer from "../featured/social_contact/socialSlice";
import rulesSlice from "../featured/rules/rulesSlice";
import userReducer from "../featured/user/userSlice";
import registerReducer from "../featured/user/registerSlice";
import menuReducer from "../featured/mobile_menu/menuSlice";
import subscribeReducer from "../featured/user/subscribeSlice";
import newsFilterReducer from "../featured/news/newsFilterSlice";
import searchReducer from "../featured/search/searchSlice";
import liveDonationReducer from "../featured/reports/liveDonationSlice";
import regionDonationReducer from "../featured/reports/regionDonationSlice";
import yearDonationReducer from "../featured/reports/yearDonationSlice";
import subscriptionsReducer from "../featured/subscribtion/subscribtionSlice";
import getUserDataReducer from "../featured/user-profile/getUserSlice";
import updateProfileReducer from "../featured/user-profile/updateProfileSlice";
import changePasswordSlice from "../featured/user-profile/changePasswordSlice";
import forgetPasswordSlice from "../featured/user/forgotPasswordSlice";
import updatePasswordSlice from "../featured/user/updatePasswordSlice";
import addCardSlice from "../featured/payment/addCartSlice";
import donatePaymentSlice from "../featured/payment/donatePaymentSlice";
import excuteCardSlice from "../featured/payment/ExcuteSlice";
import userRegionDonationSlice from "../featured/user-profile/reportDonation/regionSlice";
import userYearDonationSlice from "../featured/user-profile/reportDonation/yearDonationSlice";
import userActiveDonationSlice from "../featured/user-profile/reportDonation/activeDonationSlice";
import commonDonationSlice from "../featured/user-profile/reportDonation/commonSlice";
import userDeleteCardSlice from "../featured/payment/deleteCartSlice";
import detailDonateSlice from "../featured/payment/detailDonateSlice";
import updateDonateSlice from "../featured/payment/updateDonateSlice";
import userDeleteSubscribeSlice from "../featured/payment/deleteSubscribeSlice";
import userCancelSubscribeSlice from "../featured/payment/cancelSubscribeSlice";
import liveTickerSlice from "../featured/liveTicker/liveTickerSlice";
export const store = configureStore({
  reducer: {
    submenu: submenuReducer,
    news: newsReducer,
    regions: regionReducer,
    newsDetails: newsDetailReducer,
    regionDetails: regionDetailReducer,
    report: reportReducer,
    partners: partnerReducer,
    banners: bannerReducer,
    trustee: trusteeReducer,
    publications: publicationReducer,
    mission: missionReducer,
    structure: StructureReducer,
    structureDetails: structureDetailReducer,
    about: AboutReducer,
    social: socialReducer,
    rules: rulesSlice,
    user: userReducer,
    register: registerReducer,
    menu: menuReducer,
    subscribe: subscribeReducer,
    newsFilter: newsFilterReducer,
    search: searchReducer,
    liveDonation: liveDonationReducer,
    regionDonation: regionDonationReducer,
    yearDonation: yearDonationReducer,
    subscriptions: subscriptionsReducer,
    userData: getUserDataReducer,
    updateProfile: updateProfileReducer,
    changePassword: changePasswordSlice,
    forgetPass: forgetPasswordSlice,
    updatePassword: updatePasswordSlice,
    // payment
    addCard: addCardSlice,
    donatePayment: donatePaymentSlice,
    excutedCard: excuteCardSlice,
    //
    userRegion: userRegionDonationSlice,
    userYear: userYearDonationSlice,
    userActive: userActiveDonationSlice,
    commonDonation: commonDonationSlice,
    cardDelete: userDeleteCardSlice,
    detailDonate: detailDonateSlice,
    updateDonate: updateDonateSlice,
    subscribeDelete: userDeleteSubscribeSlice,
    subscribeCancel: userCancelSubscribeSlice,
    liveTicker: liveTickerSlice,
  },
});
