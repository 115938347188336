import customFetch from "../../utils/axios";
import { toast } from 'react-toastify';
export const updateDonateThunk=async(donate,thunkAPI)=>{
  const user=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.post(`/user/update-donation`,donate,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng'),
        'Authorization':`Bearer ${JSON.parse(user)}`
      }
    });
    toast.success( localStorage.getItem('i18nextLng') !=='en' ? 'Abunə növü uğurla dəyişdirildi':'Subscription plan has been successfully changed');

    setTimeout(()=>{
      window.location.href='/cabinet/reports';
    },2000)
    return resp.data.data;
  } catch (error) {
    console.log(error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
}