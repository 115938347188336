import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../utils/axios";


const initialState={
  isLoading:false,
  error:null,
  banner:[]
}

export const getAllBanners=createAsyncThunk('banners',async(_,thunkAPI)=>{
  try {
    const resp=await customFetch.get('/banners',{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng')
      }
    });
    return resp.data.data;
  } catch (error) {
      return thunkAPI.rejectWithValue(error.response.status);
  }
})
const bannerSlice=createSlice({
  name:"banner",
  initialState,
  extraReducers:{
    [getAllBanners.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
    },
    [getAllBanners.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.banner=payload;
    },
    [getAllBanners.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export default bannerSlice.reducer;