import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import { updateProfileFLNAmeThunk, updateProfileThunk } from "./updateProfileThunk";



const initialState={
  isLoading:false,
  update:{},
  error:""
}
export const updateUser=createAsyncThunk('user/update',async(user,thunkAPI)=>{
  return updateProfileThunk('/user/profile-update',user,thunkAPI);
})

export const updateFLNameUser=createAsyncThunk('user/fl_name',async(user,thunkAPI)=>{
  return updateProfileFLNAmeThunk('/user/fl-name-update',user,thunkAPI);
})


const updateProfileSlice=createSlice({
  name:'update',
  initialState,
  extraReducers:{
    [updateUser.pending]:(state)=>{
      state.isLoading=true;
      state.error='';
    },
    [updateUser.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.update=payload;
      state.error='';
    },
    [updateUser.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    },
    [updateFLNameUser.pending]:(state,)=>{
      state.isLoading=true;
      state.error='';
    },
    [updateFLNameUser.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.update=payload;
      state.error='';
    },
    [updateFLNameUser.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    },
  }
})

export default updateProfileSlice.reducer;
