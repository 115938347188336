import customFetch from "../../utils/axios";


export const allPartnersThunk=async(_,thunkAPI)=>{
  try {
    const resp=await customFetch.get('/partners');
    return resp.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
}