import customFetch from "../../utils/axios";

export const getSearchThunk=async({keyword},thunkAPI)=>{
  const {page,paginate}=thunkAPI.getState().search;
  try {
    const resp=await customFetch.get(`news?search=${keyword}&paginate=${paginate}&page=${page}`,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng')
      }
    });
    return resp.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
}