import React from 'react'
import { useRef } from 'react'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Logo from '../../assets/images/fif.svg'
import { closeModal, forgetModalClose } from '../../featured/user/userSlice'
import useClickOutside from '../../utils/clickable'
import ModalCloseIcon from '../icons/ModalCloseIcon'
import Login from './Login'
import Register from './Register'
import ErrorIcon from '../icons/ErrorIcon';
import { ClipLoader } from 'react-spinners';
import { forgetPassword } from '../../featured/user/forgotPasswordSlice';
const ForgetInfos = {
  email: ""
};
const Auth = () => {
  const {authModal,forget}=useSelector(state=>state.user);
  const dispatch=useDispatch();
  const {t}=useTranslation('auth');
  const [register,setRegister]=useState(false);
  const [forgetEmail, setForgetEmail] = useState(ForgetInfos);
  const {isLoading:ForgetLoading,error:ForgetError}=useSelector(state=>state.forgetPass);
  const { email} = forgetEmail;
  const handleForget = (e) => {
    const { name, value } = e.target;
    setForgetEmail({ ...forgetEmail, [name]: value });
  };
  const forgetEmailValidation = Yup.object({
    email: Yup.string()
      .required(t('forget.email.error.empty'))
      .email(t('forget.email.error.wrong'))
      .max(100),
  });
  const subscribeSubmit = async () => {
    try {
      dispatch(forgetPassword({email}))
      setForgetEmail(ForgetInfos)
    } catch (error) {
      console.log(error)
    }
  };
  const ModalContent=useRef(null);
  const ForgetPasswordRef=useRef(null);
  // useClickOutside(ModalContent,()=>{
  //   // dispatch(closeModal())
  //   window.localStorage.getItem('user') && window.localStorage.removeItem('user')
  //   window.sessionStorage.getItem('user') && window.sessionStorage.removeItem('user')
  // })


  useClickOutside(ForgetPasswordRef,()=>{
    dispatch(closeModal())
    dispatch(forgetModalClose())
  })

  useEffect(()=>{
    window.localStorage.getItem('user') && window.localStorage.removeItem('user')
    window.sessionStorage.getItem('user') && window.sessionStorage.removeItem('user')
  },[])

  useEffect(()=>{
    if(authModal){
      document.body.style.overflowY="hidden !important";
      document.body.style.height='100vh';
    }
  },[authModal])

  return (
    <div className={`${authModal ? 'auth__modal':''}`}>
        { forget ? (
          <div className='forget_password_content' ref={ForgetPasswordRef}>
              <div className="forget_password__head">
                <h5 className='forget_password__title'>{t('forget.title')}</h5>
                <button type="button" className='forget_close' onClick={()=>{
                  dispatch(closeModal())
                  dispatch(forgetModalClose())
                }}>
                  <ModalCloseIcon/>
                </button>
              </div>
              <div className="forget_password_body">
              <Formik
            enableReinitialize
            initialValues={{
              email,
            }}
            validationSchema={forgetEmailValidation}
            onSubmit={() => {
              subscribeSubmit();
            }}
          >
            {(formik) => (
              <Form>
                <div className='forget_password_form'>
                  <div className='alert_group'>
                    {ForgetError && <span className='alert_form'>{t('forget.email.error.dont_register')}</span>}
                  </div>
                    <label htmlFor="email_forget" >{t('forget.email.label')}</label>
                    <input type="text" className={`forget_email_input ${formik.errors.email && 'error_email'}`} id="email_forget" name="email" onChange={handleForget} placeholder={t('forget.email.placeholder')}/>
                  {formik.errors.email && <span className='error_message'><ErrorIcon/> {formik.errors.email}</span>}
                  <div className='forget_button'>
                    <button type="submit" disabled={ForgetLoading}>
                    {ForgetLoading ? <><ClipLoader color="#fff" size={15}/></>:<>{t('auth_button')}</>}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
              </div>
          </div>
        ):(
             <div className='auth__modal__content' ref={ModalContent}>
              <div className="auth__modal__content__header">
                <img src={Logo} alt="logo" className="modal_logo"/>
                <button type="button" className='modal_close' onClick={()=>dispatch(closeModal())}>
                  <ModalCloseIcon/>
                </button>
              </div>
             <div className="auth__modal__content__main">
               <div className="auth__modal__content__main__buttons">
                 <button type="button" className={`${register && 'active'}`} onClick={()=>{
                   setRegister(true)
                   }}>{t('register')}</button>
                 <button type="button" className={`${!register && 'active'}`} onClick={()=>{
                   setRegister(false)
                 }}>{t('login')}</button>
               </div>
               <div className='auth_section'>
                 {register ? <Register/>: <Login/>}
               </div>
             </div>
           </div>
        )}
    </div>
  )
}

export default Auth
