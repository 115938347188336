import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { regionsDetailThunk } from "./regionDetailThunk";

const initialState={
  isLoading:false,
  error:null,
  regionDetails:{},
  media:[],
}

export const getRegionDetail=createAsyncThunk('regionDetail',regionsDetailThunk)

const regionDetailsSlice=createSlice({
  name:"newsDetailsSlice",
  initialState,
  extraReducers:{
    [getRegionDetail.pending]:(state)=>{
      state.isLoading=true;
    },
    [getRegionDetail.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.regionDetails=payload;
      state.media=payload.media;
    },
    [getRegionDetail.rejected]:(state,{payload})=>{
      state.error=payload;
    }
  }
})

export default regionDetailsSlice.reducer;