import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { allnewsThunk } from "./newsThunk";

const initialState={
  isLoading:false,
  filterMenu:false,
  error:null,
  news:[]
}

export const getAllnews=createAsyncThunk('news',allnewsThunk)

export const newsSlice=createSlice({
  name:"submenu",
  initialState,
  reducers:{
    openDropdown:  (state) => {
      state.filterMenu = true;
    },
    closeDropdown: (state) => {
      state.filterMenu =false
    },
    toggleDropdown:(state)=>{
      state.filterMenu=!state.filterMenu;
    }
  },
  extraReducers:{
    [getAllnews.pending]:(state)=>{
      state.isLoading=true;
    },
    [getAllnews.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.news=payload;
    },
    [getAllnews.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload
    }
  }
})

export const {openDropdown,closeDropdown,toggleDropdown}=newsSlice.actions

export default newsSlice.reducer