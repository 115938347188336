import { createSlice } from "@reduxjs/toolkit";

export const submenuSlice=createSlice({
  name:"submenu",
  initialState:{
    about:false,
    search:false,
  },
  reducers:{
    openMenu:  (state) => {
      state.about = true;
    },
    closeMenu: (state) => {
      state.about =false
    },
  }
})

export const {openMenu,closeMenu}=submenuSlice.actions

export default submenuSlice.reducer