import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMissionThunk } from "./missionThunk";

const initialState={
  isLoading:false,
  error:null,
  mission:{}
}

export const getMission=createAsyncThunk('mission',getMissionThunk)

const missionSlice=createSlice({
  name:"mission",
  initialState,
  extraReducers:{
    [getMission.pending]:(state)=>{
      state.isLoading=true;
    },
    [getMission.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.mission=payload;
    },
    [getMission.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})


export default missionSlice.reducer