import customFetch from "../../utils/axios";


export const filterNewsThunk=async(_,thunkAPI)=>{
  const {regionID,page,paginate}=thunkAPI.getState().newsFilter;
  try {
    const resp=await customFetch.get(`/news?region_id=${regionID}&paginate=${paginate}&page=${page}`,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng')
      }
    });
    return resp.data.data;
  } catch (error) {
    console.log(error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
}