import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({title,canonical}) => {
  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>{title}</title>
      <meta name="title" content={title}/>
      <link rel="canonical" href={`https://fif.az/${canonical}`}/>
      <meta name="description" content="FUTBOLUN İNKİŞAF FONDUNUN ƏSAS MİSSİYASI BÖLGƏ FUTBOL KLUBLARINA DAYANIQLI MALİYYƏ MƏNBƏLƏRİ TAPMAQDA KÖMƏK ETMƏKDİR."/>
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://new-fif-az.vercel.app/"/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content="FUTBOLUN İNKİŞAF FONDUNUN ƏSAS MİSSİYASI BÖLGƏ FUTBOL KLUBLARINA DAYANIQLI MALİYYƏ MƏNBƏLƏRİ TAPMAQDA KÖMƏK ETMƏKDİR."/>
      <meta property="og:image" content=""/>

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content="https://new-fif-az.vercel.app/"/>
      <meta property="twitter:title" content={title}/>
      <meta property="twitter:description" content="FUTBOLUN İNKİŞAF FONDUNUN ƏSAS MİSSİYASI BÖLGƏ FUTBOL KLUBLARINA DAYANIQLI MALİYYƏ MƏNBƏLƏRİ TAPMAQDA KÖMƏK ETMƏKDİR."/>
      <meta property="twitter:image" content=""/>
    </Helmet>
  )
}

Meta.defaultProps = {
  title: 'Futbolun İnkişaf Fondu',
  canonical: ''
}

export default Meta