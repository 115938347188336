import customFetch from "../../../utils/axios";


export const getCommonDonationThunk=async(_,thunkAPI)=>{
  const userData=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.get('/user/donations/common',{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng'),
        'Authorization':`Bearer ${JSON.parse(userData)}`
      }
    });
    return resp.data.data;
  } catch (error) {
    if(error.response.status === 401){
      window.location.href='/'
      sessionStorage.removeItem('user');
      localStorage.removeItem('user');
      return  window.location.reload();
    }
    return thunkAPI.rejectWithValue(error.message);
  }
}