import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userSubscribeDeleteThunk } from "./deleteSubscribeThunk";

const initialState = {
  isLoading: false,
  subscribeDelete: false,
  error: "",
};
export const userSubscribeDelete = createAsyncThunk(
  "user/subscribe-delete",
  async (user, thunkAPI) => {
    return userSubscribeDeleteThunk("user/delete/donation", user, thunkAPI);
  }
);

const userDeleteSubscribeSlice = createSlice({
  name: "subscribeDelete",
  initialState,
  extraReducers: {
    [userSubscribeDelete.pending]: (state) => {
      state.isLoading = true;
      state.subscribeDelete = false;
      state.error = "";
    },
    [userSubscribeDelete.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.subscribeDelete = true;
      state.error = "";
    },
    [userSubscribeDelete.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.subscribeDelete = false;
      state.error = payload;
    },
  },
});

export default userDeleteSubscribeSlice.reducer;
