import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const cl = window.location.pathname;
  if (user.length === 0 && cl !== "/donation/cancel") {
    return (
      <Navigate to='/' />
    );
  }
  return children;
};
export default ProtectedRoute;