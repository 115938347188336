import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import { subscribeUserThunk } from "./subscribeThunk";



const initialState={
  isLoading:false,
  success:false,
  error:null,
}


export const subscribeUser=createAsyncThunk('user/subscribe',async(user,thunkAPI)=>{
  return subscribeUserThunk('user/subscription',user,thunkAPI);
})

const subscribeSlice=createSlice({
  name:'subscribe',
  initialState,
  extraReducers:{
    [subscribeUser.pending]:(state)=>{
        state.isLoading=true;
        state.error=null;
        state.success=false
    },
    [subscribeUser.fulfilled]:(state,{payload})=>{
        state.isLoading=false;
        state.error=null;
        state.success=true;
    },
    [subscribeUser.rejected]:(state,{payload})=>{
        state.isLoading=false;
        state.error=payload;
        state.success=false
    }
  }
})

export default subscribeSlice.reducer;
