import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveDonationThunk } from "./liveDonationThunk";


const initialState={
  isLoading:false,
  error:null,
  donation:[]
}

export const getLiveDonation=createAsyncThunk('getLiveDonation',getLiveDonationThunk)

const liveDonationSlice=createSlice({
  name:"liveDonation",
  initialState,
  extraReducers:{
    [getLiveDonation.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
      state.donation=[];
    },
    [getLiveDonation.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.donation=payload;
    },
    [getLiveDonation.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
      state.donation=[];
    }
  }
})

export default liveDonationSlice.reducer;