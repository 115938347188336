import customFetch from "../../../utils/axios";



export const getUserYearDonationThunk=async(_,thunkAPI)=>{
  const userData=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  const {year}=thunkAPI.getState().userYear;
  try {
    const resp=await customFetch.get(`/user/donations/year?year=${year}`,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng'),
        'Authorization':`Bearer ${JSON.parse(userData)}`

      }
    });
    return resp.data.data.month;
  } catch (error) {
    console.log(error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
}