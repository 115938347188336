import React from 'react'

const ArrowPrevIcon = () => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.05078 1L1.05078 6L6.05078 11" stroke="#606566"/>
    </svg>
  )
}

export default ArrowPrevIcon