import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getUserRegionDonationThunk } from "./regionThunk";


const initialState={
  isLoading:false,
  error:null,
  regionDonation:{},
  fondDonation:null,
}

export const getUserRegionDonation=createAsyncThunk('getUserRegionDonation',getUserRegionDonationThunk)

const userRegionDonationSlice=createSlice({
  name:"userRegionDonation",
  initialState,
  extraReducers:{
    [getUserRegionDonation.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
      state.regionDonation={}
      state.fondDonation=null
    },
    [getUserRegionDonation.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.regionDonation=payload.regions;
      state.fondDonation=payload.common;
    },
    [getUserRegionDonation.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
      state.regionDonation={};
      state.fondDonation=null;
    }
  }
})

export default userRegionDonationSlice.reducer;