import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllStructureThunk } from "./StructureThunk";


const initialState={
  isLoading:false,
  structure:{},
  error:null
}

export const getAllStructure=createAsyncThunk('allStructure',getAllStructureThunk)

const structureSlice=createSlice({
  name:"structure",
  initialState,
  extraReducers:{
    [getAllStructure.pending]:(state)=>{
      state.isLoading=true;
    },
    [getAllStructure.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.structure=payload;
    },
    [getAllStructure.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export default structureSlice.reducer;