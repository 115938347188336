import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import { updatePasswordThunk } from "./updatePasswordThunk";



const initialState={
  isLoading:false,
  success:'',
  error:'',
}


export const updatePassword=createAsyncThunk('user/update-password',async(user,thunkAPI)=>{
  return updatePasswordThunk('auth/password/reset',user,thunkAPI);
})

const updatePasswordSlice=createSlice({
  name:'updatePassword',
  initialState,
  extraReducers:{
    [updatePassword.pending]:(state)=>{
        state.isLoading=true;
        state.error='';
        state.success='';
    },
    [updatePassword.fulfilled]:(state,{payload})=>{
        state.isLoading=false;
        state.error='';
        state.success=payload;
    },
    [updatePassword.rejected]:(state,{payload})=>{
        state.isLoading=false;
        state.error=payload;
        state.success=''
    }
  }
})

export default updatePasswordSlice.reducer;
