import customFetch from "../../utils/axios";


export const getRegionDonationThunk=async(_,thunkAPI)=>{
  try {
    const resp=await customFetch.get('/donations/by/region',{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng')
      }
    });
    return resp.data.data;
  } catch (error) {
    console.log(error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
}