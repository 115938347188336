import customFetch from "../../utils/axios";
import { toast } from 'react-toastify';

export const userCardDeleteThunk=async(url,user,thunkAPI)=>{
    const userData=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.post(url,user,{
        headers:{
          'x-localization': localStorage.getItem('i18nextLng'),
          'Authorization':`Bearer ${JSON.parse(userData)}`
        }
      });
      toast.success( localStorage.getItem('i18nextLng') !=='en' ? 'Kartınız uğurla silindi':'Your card has been deleted successfully');
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
}