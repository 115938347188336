import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import { forgetPasswordThunk } from "./forgetPasswordThunk";



const initialState={
  isLoading:false,
  success:'',
  error:'',
}


export const forgetPassword=createAsyncThunk('user/forget-password',async(user,thunkAPI)=>{
  return forgetPasswordThunk('auth/forgot-password',user,thunkAPI);
})

const forgetPasswordSlice=createSlice({
  name:'forgetPassword',
  initialState,
  extraReducers:{
    [forgetPassword.pending]:(state)=>{
        state.isLoading=true;
        state.error='';
        state.success='';
    },
    [forgetPassword.fulfilled]:(state,{payload})=>{
        state.isLoading=false;
        state.error='';
        state.success=payload;
    },
    [forgetPassword.rejected]:(state,{payload})=>{
        state.isLoading=false;
        state.error=payload;
        state.success=''
    }
  }
})

export default forgetPasswordSlice.reducer;
