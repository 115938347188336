import { createSlice,createAsyncThunk} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { changePasswordThunk } from "./changePasswordThunk";

const lang =localStorage.getItem('i18nextLng');

const initialState={
  isLoading:false,
  changePassword:{},
  error:""
}
export const userChangePassword=createAsyncThunk('user/change-password',async(user,thunkAPI)=>{
  return changePasswordThunk('/user/change-password',user,thunkAPI);
})


const changePasswordSlice=createSlice({
  name:'change-password',
  initialState,
  extraReducers:{
    [userChangePassword.pending]:(state)=>{
      state.isLoading=true;
      state.error='';
    },
    [userChangePassword.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.changePassword=payload;
      toast.success(lang === 'az' ? 'Password uğurlu şəkildə yeniləndi':'Password has been updated successfully')
      state.error='';
    },
    [userChangePassword.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export default changePasswordSlice.reducer;
