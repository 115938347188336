import customFetch from "../../utils/axios";



export const getUserYearDonationThunk=async(report,thunkAPI)=>{
  const userData=localStorage.getItem('user') || sessionStorage.getItem('user') || [];
  try {
    const resp=await customFetch.get(report,{
      headers:{
        'x-localization': localStorage.getItem('i18nextLng'),
        'Authorization':`Bearer ${JSON.parse(userData)}`

      }
    });
    return resp.data.data;
  } catch (error) {
    console.log(error.message)
    return thunkAPI.rejectWithValue(error.message);
  }
}