import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateDonateThunk } from "./updateDonateThunk";

const initialState={
  isLoading:false,
  error:null,
  updateDonate:"",
  successChange:false
}

export const updateDonatePost=createAsyncThunk('updateDonatePost',updateDonateThunk)

const updateDonateSlice=createSlice({
  name:"updateDonate",
  initialState,
  extraReducers:{
    [updateDonatePost.pending]:(state)=>{
      state.error=null;
      state.isLoading=true;
      state.successChange=false
    },
    [updateDonatePost.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.updateDonate=payload;
      state.successChange=true;
    },
    [updateDonatePost.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
      state.successChange=false;
    }
  }
})


export default updateDonateSlice.reducer