import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getUserYearDonationThunk} from "./yearDonationThunk";


const initialState={
  year:new Date().getFullYear(),
  isLoading:false,
  error:null,
  yearDonation:{}
}

export const getUserYearDonation=createAsyncThunk('getUserYearDonation',getUserYearDonationThunk)

const userYearDonationSlice=createSlice({
  name:"userYearDonation",
  initialState,
  extraReducers:{
    [getUserYearDonation.pending]:(state)=>{
      state.isLoading=true;
      state.error=null;
      state.yearDonation={};
    },
    [getUserYearDonation.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.error=null;
      state.yearDonation=payload;
    },
    [getUserYearDonation.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
      state.yearDonation={};
    }
  }
})

export default userYearDonationSlice.reducer;