import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDonatePaymentThunk } from "./donatePaymentThunk";

const initialState={
  isLoading:false,
  error:null,
  donate:{}
}

export const donateSingleAndSubscibe=createAsyncThunk('singleAndSubscribe',getDonatePaymentThunk)

const donatePaymentSlice=createSlice({
  name:"singleAndSubscribe",
  initialState,
  extraReducers:{
    [donateSingleAndSubscibe.pending]:(state)=>{
      state.error=null;
      state.isLoading=true;
    },
    [donateSingleAndSubscibe.fulfilled]:(state,{payload})=>{
      state.isLoading=false;
      state.donate=payload;
    },
    [donateSingleAndSubscibe.rejected]:(state,{payload})=>{
      state.isLoading=false;
      state.error=payload;
    }
  }
})

export default donatePaymentSlice.reducer